import { computed } from 'vue'

import { useProductsStore } from '~/store/productsStore'
import { useNewSettingsStore } from '~/store/newSettingsStore'
import { getAttributes } from '~/utils/shop/attributes'
import { useFilterStore } from '~/store/filterStore'

import { SHOP_ROUTES_NAMES } from '~/constants'
import { CERTIFICATES } from '~/constants/gemstones'

// TODO: move to utils? (it was in @filters)
const hasValue = (s) => {
  if (Array.isArray(s)) return s.length > 0
  return !!s
}

// TODO: better to use utils or helpers? since useShop composable is accessible only in component.setup, and it often used in store or middlewares
export function useShop() {
  const digitsRE = /(\d{3})(?=\d)/g
  let _currency = '€'
  let ts = ',' //thousand separator

  const route = useRoute()
  const { t } = useI18n()
  const productsStore = useProductsStore()
  const { currentCountry, currentCurrency } = storeToRefs(useNewSettingsStore())
  const { getCurrentCurrencySymbol } = useNewSettingsStore()
  const filterStore = useFilterStore()

  const { selectedDiamonds } = storeToRefs(productsStore)

  // currency
  try {
    _currency = getCurrentCurrencySymbol

    if (currentCurrency.value?.Id === 17) {
      //is CHF
      ts = "'"
    }
  } catch (e) {}

  // computeds
  const currentStoneType = computed(() => {
    return (
      route.query.stoneType ||
      (selectedDiamonds.value && selectedDiamonds.value.length && selectedDiamonds.value[0].StoneTypeID) ||
      1
    )
  })

  const currentGemType = computed(() => {
    return (
      parseInt(route.query.gemType || (selectedDiamonds.value.length && selectedDiamonds.value[0].GemTypeID)) ||
      undefined
    )
  })
  //
  const currentShapeId = computed(() => {
    return (
      parseInt(route.query.shape || (selectedDiamonds.value.length && selectedDiamonds.value[0].ShapeId)) || undefined
    )
  })

  const currentGemName = computed(() => {
    switch (currentGemType.value) {
      case 367:
        return t('sapphire', 'Sapphire')
      case 371:
        return t('emerald', 'Emerald')
      case 366:
      default:
        return t('ruby', 'Ruby')
    }
  })

  const $isLoose = computed(() => {
    return route.query.category == 7
  })

  const $isSingle = computed(() => {
    return (route.query.diamondType || route.query.item) == -1
  })

  const $isPair = computed(() => {
    return (
      (route.query.diamondType || route.query.item) == -2 || route.query.category == 5 || route.query.subcategory == 15
    )
  })

  const $isFancy = computed(() => {
    return (route.query.diamondType || route.query.item) == -3
  })

  const $isDiamond = computed(() => {
    return currentStoneType.value == 1
  })

  const $isGemstone = computed(() => {
    return currentStoneType.value == 2
  })

  const $isRuby = computed(() => {
    return currentGemType.value == 366
  })

  const $isSapphire = computed(() => {
    return currentGemType.value == 367
  })

  const $isEmerald = computed(() => {
    return currentGemType.value == 371
  })

  const $isLab = computed(() => {
    return currentStoneType.value == 3
  })

  const $isRound = computed(() => {
    return currentShapeId.value == 1
  })
  const $isWhite = computed(() => {
    return ($isDiamond.value || $isLab.value) && !$isFancy.value
  })

  const $stoneLabel = computed(() => {
    // convert `route.query.fancyColorId` to Number since it's string, in legacy it's a Number
    if (($isDiamond.value || $isLab.value) && !Number(route.query.fancyColorId)) return 'diamond'
    else if ($isGemstone.value) return 'gemstone'
    else if (route.query.fancyColorId) {
      return $colouredStone.value
    }
  })

  const $colouredStone = computed(() => {
    const fancyText = t('fancy-caption-text', 'fancy intense {colour} diamond')
    const colors = getAttributes('fancyColor').map((color) => ({
      value: color.Id,
      label: color.ShortName,
    }))
    const selectedColor = colors.find((color) => color.value == route.query.fancyColorId)
    if (selectedColor && selectedColor.label) {
      return fancyText.replace('{colour}', selectedColor.label.toLowerCase())
    }
  })

  const $stoneLabel2 = computed(() => {
    if ($isDiamond.value) return 'diamond'
    else if ($isLab.value) return 'lab-grown-diamond'
    else if ($isGemstone.value) return 'gemstone'
  })

  const $stoneLabel2Alt = computed(() => {
    if ($isDiamond.value) return t('diamond', 'diamond')
    else if ($isLab.value) return t('lab-grown-diamond', 'lab-grown diamond')
    else if ($isGemstone.value) return t('gemstone', 'gemstone')
  })

  const $stonesLabel = computed(() => {
    if ($isDiamond.value || $isLab.value) return 'diamonds'
    else if ($isGemstone.value) return 'gemstones'
  })

  const $currency = computed(() => {
    // TODO: get value from settings store
    return window._77Settings.Currency.Symbol
  })

  const $isExpress = computed(() => {
    return route.query.isExpressShop || route.query.stockNumber
  })

  const $chargeVat = computed(() => {
    return currentCountry.value?.ChargeVat
  })

  const vatInfo = computed(() => {
    return {
      ChargeVat: currentCountry.value?.ChargeVat,
      VatPercent: currentCountry.value?.VatPercent,
      Name: currentCountry.value?.Name,
    }
  })

  const storeValue = (key, includeDefault = false) => {
    try {
      const query = filterStore.getQuery(includeDefault)

      if (!(key instanceof Array)) {
        if (query['min-' + key] || query['max-' + key]) {
          return [query['min-' + key], query['max-' + key]]
        } else {
          return query[key]
        }
      } else {
        return key.reduce((accumulator, k) => {
          if (query['min-' + k] || query['max-' + k]) {
            accumulator[k] = [query['min-' + k], query['max-' + k]]
          } else {
            accumulator[k] = query[k]
          }
          return accumulator
        }, {})
      }
    } catch (e) {
      console.error(e)
    }
  }

  const storeValueArray = (key, includeDefault = false) => {
    const values = storeValue(key, includeDefault)

    if (!values) {
      return []
    }

    return values instanceof Array ? values : values.split(',')
  }

  const hasStoreValue = (key, includeDefault = false) => {
    const value = storeValue(key, includeDefault)
    // console.log('hasStoreValue', key, value, !Array.isArray(key) && hasValue(value))
    if (!Array.isArray(key)) {
      return hasValue(value)
    } else {
      return key.reduce((accumulator, k) => accumulator || hasValue(value[k]), false)
    }
  }

  const $stoneType = (stone, length = 1) => {
    const pluralize = length == 1 ? '' : 's'

    if (!stone || stone.StoneTypeID == 1) return t(`diamond${pluralize}`, `diamond${pluralize}`)
    else if (stone.StoneTypeID == 3) return t(`lab-grown-diamond${pluralize}`, `lab-grown diamond${pluralize}`)
    else if (stone.StoneTypeID == 2) {
      return stone.GemTypeName.toLowerCase()
    }
  }

  const $removeDiamondLink = (removeDiamond = true, query = null) => {
    const path = window.location.pathname
    const search = new URLSearchParams(query || window.location.search)
    // Reworked $removeDiamondLink since it's new routing system
    search.delete('opened')
    search.delete('step')

    if (route.query.category != 7) search.set('step', SHOP_ROUTES_NAMES.ITEM_DIAMOND)

    if (removeDiamond) {
      search.delete('diamond')
      search.delete('selectedDiamond')
    }

    return `${path}?${search.toString()}`
  }

  // utils
  const currency = (value, currency, decimals) => {
    value = parseFloat(value)
    if (!isFinite(value) || (!value && value !== 0)) return ''
    currency = currency != null ? currency : _currency
    decimals = decimals != null ? decimals : 2

    const stringified = Math.abs(value).toFixed(decimals)
    const _int = decimals ? stringified.slice(0, -1 - decimals) : stringified
    const i = _int.length % 3
    const head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? ts : '') : ''
    const _float = decimals ? stringified.slice(-1 - decimals) : ''
    const sign = value < 0 ? '-' : ''

    return sign + currency + head + _int.slice(i).replace(digitsRE, '$1' + ts) + _float
  }
  const checkEngraving = (item) => {
    if (
      item.Id == 555 ||
      (item.Id == 895 && route.query.variation == '303') ||
      (item.Id == 988 && route.query.variation == '336') ||
      (item.Id == 886 && route.query.variation == '301') ||
      (item.Id == 504 && route.query.variation == '212') ||
      item.Id == 519 ||
      item.Id == 856 ||
      item.Id == 855
    ) {
      return false
    } else {
      return true
    }
  }

  const getCert = (diamond) => {
    if (diamond && diamond.CertId) {
      return CERTIFICATES[diamond.CertId] || 'generic'
    }
  }

  const isEcoCert = (certId, stoneType) => {
    const stone = parseInt(stoneType)
    if ((certId == CERTIFICATES.GIA && stone == 3) || (certId == CERTIFICATES.LGC && stone == 2)) {
      return true
    }
    return false
  }

  const getCertUrl = (diamond) => {
    if (!diamond) return false

    switch (diamond.CertId) {
      case CERTIFICATES.GIA: // "GIA
        return `https://www.gia.edu/report-check?reportno=${diamond.CertificateNumber}`
      case CERTIFICATES.IGI: // "IGI
        return `https://www.igi.org/reports/verify-your-report?r=${diamond.CertificateNumber}`
      case CERTIFICATES.GCAL: // "GCAL
        return `https://www.gcalusa.com/certificate-search.html?certificate_id=${diamond.CertificateNumber}`
      case CERTIFICATES.DF: // "DF
        return `https://certificate.diamondfoundry.com/download/${diamond.CertificateNumber}.pdf`
      case CERTIFICATES.LGC: // "LGC"
        return `https://www.77diamonds.com/handlers/gemcertificate.ashx?code=${diamond.Code}`
    }

    if (diamond.CertificateUrl) return diamond.CertificateUrl

    return false
  }

  const doPopup = (url, config) => {
    window.open(url, '_blank', config || 'left=100,top=100,width=980,height=860,scrollbars=yes')
  }

  const doCertificatePopup = (diamond) => {
    doPopup(getCertUrl(diamond))
  }

  return {
    // computeds
    currentStoneType,
    currentGemType,
    currentShapeId,
    currentGemName,
    vatInfo,
    $isLoose,
    $isSingle,
    $isPair,
    $isFancy,
    $isDiamond,
    $isGemstone,
    $isRuby,
    $isSapphire,
    $isEmerald,
    $isLab,
    $isRound,
    $isWhite,
    $stoneLabel,
    $colouredStone,
    $stoneLabel2,
    $stoneLabel2Alt,
    $stonesLabel,
    $currency,
    $isExpress,
    $chargeVat,

    // methods
    storeValue,
    storeValueArray,
    hasStoreValue,
    $stoneType,
    $removeDiamondLink,

    // utils
    checkEngraving,
    currency,
    isEcoCert,
    doPopup,
    getCertUrl,
    getCert,
    doCertificatePopup,
  }
}
