import type { CountryItem, Currency, Language, LanguageSelection, userPreferences } from '~/types/currencySelection'

export const useNewSettingsStore = defineStore('newSettings', () => {
  const { getCountries, getCurrencies, getPhoneNumbers, getLanguages } = useUtils()
  const { $i18n } = useNuxtApp()
  const countries = ref<CountryItem[]>([])
  const currentCountry = ref<CountryItem>(null)
  const currentCountryId = useCookie('currentCountryId')
  const currencies = ref<Currency[]>([])
  const currentCurrency = ref<Currency>(null)
  const currentCurrencyId = useCookie('currentCurrencyId')
  const languages = ref<LanguageSelection[]>([])
  const currentLanguage = ref<Language>(null)
  const currentLanguageId = useCookie('currentLanguageId')
  const phoneNumbers = ref<any[]>([])
  const phoneNumber = ref<string | null>('')
  const userPreferences = ref<userPreferences | null>(null)
  //ToDo: types
  const diamondPreferences = ref<any | null>(null)

  const getLanguageCurrencyCode = computed(() => {
    const countryCode = currentCountry.value?.CountryCodeISO || userPreferences.value?.CountryName || 'GB'
    const currencyCode = currentCurrency.value?.Name || userPreferences.value?.CurrencyCode || 'GBP'
    return `${countryCode} / ${currencyCode}`
  })

  const getCurrentCurrencySymbol = computed(() => {
    const formatter = new Intl.NumberFormat(currentCountry.value.CultureInfo, {
      style: 'currency',
      currency: currentCurrency.value.Name,
    })

    const parts = formatter.formatToParts(0)
    const currencyPart = parts.find((part) => part.type === 'currency')

    return currencyPart ? currencyPart.value : '£'
  })

  const countryPhoneNumber = computed(() => {
    return phoneNumber.value || currentCountry.value?.CountryPhoneNumber
  })

  const setCountries = async () => {
    countries.value = await getCountries()
  }

  const setCurrentCountry = (country?: CountryItem) => {
    if (country) {
      currentCountry.value = country
      currentCountryId.value = country.Id
      return
    }
    if (!countries.value) {
      return
    }
    const countryIdToFind = currentCountryId.value || userPreferences.value?.CountryId
    const result = countries.value?.find((c) => c.Id === countryIdToFind)
    if (result) {
      currentCountry.value = result
      currentCountryId.value = result.Id
    }
  }

  const setCurrentCurrency = (currency?: Currency) => {
    if (currency) {
      currentCurrency.value = currency
      currentCurrencyId.value = currency.Id
      reloadNuxtApp()
      return
    }
    if (!currencies.value) {
      return
    }
    const currencyIdToFind = currentCurrencyId.value || userPreferences.value.CurrencyId
    const result = currencies.value?.find((c) => c.Id === currencyIdToFind)
    if (result) {
      currentCurrency.value = result
      currentCurrencyId.value = result.Id
      reloadNuxtApp()
    }
  }

  const setCurrencies = async () => {
    currencies.value = await getCurrencies()
  }

  const setLanguages = async () => {
    const languageId = $i18n.locales.value.find((locale) => locale.code === $i18n.locale.value)?.id || 1
    languages.value = await getLanguages(languageId, currentCountry.value?.Id, currentCurrency.value?.Id)
  }

  const setPhoneNumbers = async () => {
    phoneNumbers.value = await getPhoneNumbers()
    const phoneNumberToSet = phoneNumbers.value.find(
      (numberInfo) => numberInfo.Id === userPreferences.value?.CountryId,
    )?.Number

    if (phoneNumberToSet) {
      phoneNumber.value = phoneNumberToSet
    }
  }

  const setCurrentLanguage = (lang?: Language) => {
    if (lang) {
      currentLanguage.value = lang
      currentLanguageId.value = lang.Id
    } else {
      const languageId = $i18n.locales.value.find((locale) => locale.code === $i18n.locale.value)?.id || 1
      const languageIdToFind = currentLanguageId.value || languageId
      const language = languages.value?.LanguageSelections.find(
        (language) => Number(language.Language.Id) === languageIdToFind,
      )?.Language
      currentLanguage.value = language
      currentLanguageId.value = language.Id
    }
  }

  const setUserPreferences = (preferences: userPreferences) => {
    userPreferences.value = preferences
  }

  const setDiamondPreferences = (preferences: any) => {
    diamondPreferences.value = preferences
  }

  return {
    currentCountry,
    currentCurrency,
    currentLanguage,
    phoneNumber,
    userPreferences,
    diamondPreferences,
    getLanguageCurrencyCode,
    getCurrentCurrencySymbol,
    countryPhoneNumber,
    setCurrentCountry,
    setCurrentCurrency,
    setCurrentLanguage,
    setUserPreferences,
    setDiamondPreferences,
    setCountries,
    setCurrencies,
    setLanguages,
    setPhoneNumbers,
    countries,
    currencies,
    languages,
    phoneNumbers,
  }
})
